import { IFetchConfig } from "@elsa-esa/lib/helpers/fetch";
import fetch from "./http-client";

import { Building, CreateBuildingDto, UpdateBuildingDto } from "./data-contracts";

export const buildingControllerCreate = (data: CreateBuildingDto, params?: IFetchConfig) =>
  fetch<Building>("POST", `building`, data, params);

export const buildingControllerFindAll = (params?: IFetchConfig) => fetch<Building[]>("GET", `building`, null, params);

export const buildingControllerFindOne = (id: number, params?: IFetchConfig) =>
  fetch<Building[]>("GET", `building/${id}`, null, params);

export const buildingControllerUpdate = (id: number, data: UpdateBuildingDto, params?: IFetchConfig) =>
  fetch<Building>("PUT", `building/${id}`, data, params);

export const buildingControllerRemove = (id: number, params?: IFetchConfig) =>
  fetch<void>("DELETE", `building/${id}`, null, params);
