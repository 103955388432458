import fetch from "@elsa-esa/lib/helpers/fetch";
import { CancellablePromise } from "@elsa-esa/lib/interfaces/api";

let _apiUrl = "https://testintercom.myelsa.ru/api/";
export const setApiUrl = (apiUrl: string) => (_apiUrl = apiUrl);

let _authorization: string = null;
export const setApiAuthorization = (authorization: string) => (_authorization = authorization);

export default <R = any,>(...args: Parameters<typeof fetch>) => {
  args[1] = _apiUrl + args[1];

  if (_authorization) {
    if (!args[3]) args[3] = {};
    if (!args[3].headers) args[3].headers = {};
    if (!args[3].headers.authorization) args[3].headers.Authorization = _authorization;
  }

  return fetch<R>(...args).catch((err) => {
    const errors = err?.error?.errors;
    throw errors || err;
  }) as CancellablePromise<R>;
};
