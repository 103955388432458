import * as React from "react";
import { action, map, onMount, onSet } from "nanostores";
import { useStore, UseStoreOptions } from "@nanostores/react";
import { useNavigate } from "react-router-dom";
import { buildingControllerFindAll } from "src/api/building";
import { Building } from "src/api/data-contracts";
import { CancellablePromise } from "@elsa-esa/lib/interfaces/api";
import arrayToIdIndex from "@elsa-esa/lib/helpers/arrayToIdIndex";

export interface BuildingsStore {
  list: number[],
  dict: Record<string, Building>,
  selected: number,
  loader: CancellablePromise<Building[]>,
}

export const buildingsStore = map<BuildingsStore>({
  list:     null,
  dict:     null,
  selected: null,
  loader:   null,
});

const getValidSelected = (id: number, dict: BuildingsStore["dict"], list: BuildingsStore["list"]) => {
  return dict[id] ? id : list[0];
}

export const loadBuildings = action(buildingsStore, 'loadBuildings', (store) => {
  const loader = buildingControllerFindAll().then((buildings) => {
    const dict = arrayToIdIndex(buildings);
    const list = buildings.map(b => b.id);
    const selected = getValidSelected(+localStorage.buildings_id, dict, list);

    store.set({
      dict,
      list,
      selected,
      loader,
    });
    localStorage.buildings_id = selected;
    return buildings;
  }, (err: any) => {
    if (err.status !== 401) {
      alert(err);
    }
    throw err;
  });

  loader.catch(() => {})

  store.setKey('loader', loader)
});

export const selectBuilding = action(buildingsStore, 'select', (store, id: number) => {
  const { dict, list } = store.get();
  id = getValidSelected(id, dict, list);
  store.setKey('selected', id);
  localStorage.buildings_id = id;
})

export type UseBuildingOptions = UseStoreOptions<typeof buildingsStore>;
export const useBuildingsStore = (options?: UseBuildingOptions) => useStore(buildingsStore, options);

const selectedBuildingIdConfig: UseBuildingOptions = { keys: ['selected'] }
export const useSelectedBuildingId = () => {
  const { selected } = useBuildingsStore();
  return selected;
};
const selectedBuildingConfig: UseBuildingOptions = { keys: ['selected', 'dict'] }
export const useSelectedBuilding = () => {
  const { selected, dict } = useBuildingsStore(selectedBuildingConfig);
  return dict ? dict[selected] : null;
}

const buildingConfig: UseBuildingOptions = { keys: ['dict'] }
export const useBuilding = (id: number) => {
  const { dict } = useBuildingsStore(buildingConfig);
  return dict ? dict[id] : null;
}

const buildingListConfig: UseBuildingOptions = { keys: ['list', 'dict'] }
export const useBuildingsList = () => {
  const { list, dict } = useBuildingsStore(buildingListConfig);
  if (!list) return [];
  return list.map(id => dict[id]);
}

const buildingLoaderConfig: UseBuildingOptions = { keys: ['loader'] }
export const useBuildingsLoader = () => {
  const { loader } = useBuildingsStore(buildingLoaderConfig);
  return loader;
}

export const useSelectedBuildingGuard = () => {
  const buildingId = useSelectedBuildingId();
  const { list } = useBuildingsStore();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (Array.isArray(list) && !buildingId) navigate('/', { replace: true });
  }, [list, buildingId]);
}


onMount(buildingsStore, () => {
  loadBuildings();
});