import { RouteItem } from "@elsa-esa/lib/components/App/components/Content";

const routes: RouteItem[] = [
  {
    path:      '/buildings',
    name:      'buildings',
    component: () => import('src/routes/Buildings'),
  },
  {
    path:      '/entrances',
    name:      'entrances',
    component: () => import('src/routes/Entrances'),
  },
  {
    path:      '/concierges',
    name:      'concierges',
    component: () => import('src/routes/Concierges'),
  },
  {
    path:      '/apartments',
    name:      'apartments',
    component: () => import('src/routes/Apartments'),
  },
  {
    path:      '/gates',
    name:      'gates',
    component: () => import('src/routes/Gates'),
  },
  {
    path:      '/calls',
    name:      'calls',
    component: () => import('src/routes/Calls'),
  },

  {
    path:      '*',
    name:      'notFound',
    component: () => import('@elsa-esa/lib/components/NotFoundRoute'),
  },
];

export default routes;
