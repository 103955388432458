import { MenuGroup } from "@elsa-esa/lib/components/Sidebar";
import * as React from 'react';

export type SidebarContext = {
  selectedBuildingId: number,
}

const isBuildingSelected = (context: SidebarContext) => !!context.selectedBuildingId;

const menu: MenuGroup[] = [
  {
    title: '',
    items: [
      {
        url:   "/buildings",
        title: "Дома",
        icon:  require("feather-icons/dist/icons/home.svg"),
      },
    ]
  },
  {
    title: 'Настройки дома',
    items: [
      {
        url:   "/entrances",
        title: "Подъезды",
        icon:  require("feather-icons/dist/icons/align-left.svg"),
        isActive: isBuildingSelected,
      },
      {
        url:   "/apartments",
        title: "Помещения",
        icon:  require("feather-icons/dist/icons/phone.svg"),
        isActive: isBuildingSelected,
      },
      {
        url:   "/gates",
        title: "Вызывные панели",
        icon:  require("feather-icons/dist/icons/tablet.svg"),
        isActive: isBuildingSelected,
      },
      {
        url:   "/concierges",
        title: "Консьержи",
        icon:  require("feather-icons/dist/icons/tv.svg"),
        isActive: isBuildingSelected,
      },
      {
        url:   "/calls",
        title: "История звонков",
        icon:  require("@lucide/history.svg"),
        isActive: isBuildingSelected,
      },
    ],
  },
];

menu.forEach(g => g.items.forEach(i => i.exact = true));

export default menu;
