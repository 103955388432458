import SidebarDtmfCode from "src/components/SidebarDtmfCode";

declare var ENV: Record<string, string>;

import 'destyle.css';
import "@elsa-esa/lib/styles/_colors.scss";
import "@elsa-esa/lib/styles/_fontFaceMontserrat.scss";
import "@elsa-esa/lib/styles/_typography.scss";
import { onError } from "@elsa-esa/lib/helpers/fetch";
import * as React from 'react';
import { createRoot } from "react-dom/client";
import { Route, useNavigate } from "react-router-dom";
import { setApiUrl } from "src/api/http-client";

if (process.env.NODE_ENV === 'production') setApiUrl(location.origin + '/api/');
checkAuth();

import routes from "src/routes";
import menu, { SidebarContext } from "src/menu";
import App from "@elsa-esa/lib/components/App";
import Header from "src/components/Header";
import Login from "src/routes/Login";
import { useBuildingsStore, useSelectedBuildingId } from "src/store/buildings";
import { checkAuth, unauth, useUser } from "src/store/user";

const app = (
  <App
    routes={routes}
    menu={menu}
    defaultRouteUrl="/buildings"
    baseUrl={''}
    additionalRoutes={[
      {
        path: '/login',
        lazy: () => import('src/routes/Login').then(({ default: Component }: any) => ({ Component })),
      },
    ]}
    renderSidebarFooter={() => <SidebarDtmfCode />}
    renderRouter={(renderer) => {
      const { isAuth } = useUser();
      const navigate = useNavigate();
      const { list } = useBuildingsStore()

      React.useEffect(() => {
        return onError((e) => {
          if (e.status === 401) unauth();
        });
      }, []);
      //
      React.useEffect(() => {
        if (isAuth === false) navigate('/login');
      }, [isAuth]);

      return <><Header />{Array.isArray(list) ? renderer() : null}</>
    }}
    getSidebarContext={(): SidebarContext => ({
      selectedBuildingId: useSelectedBuildingId(),
    })}
  />
);
const root = createRoot(document.getElementById('app'))
root.render(app);

