import Card from "@elsa-esa/lib/components/Card";
import Select from "@elsa-esa/lib/components/Select";
import * as React from "react";
import { selectBuilding, useBuildingsList, useSelectedBuildingId } from "src/store/buildings";
import style from "./Header.scss";

type Props = {};

const Header: React.FC<Props> = (props) => {
  const {} = props;
  const buildingsList = useBuildingsList();
  const id = useSelectedBuildingId();

  const options = React.useMemo(() => {
    return buildingsList
      ? buildingsList.map(b => ({ label: b.name, value: b.id }))
      : []
  }, [buildingsList]);

  return (
    <div className={style.container}>
      <Card rawBody>
        <div className={style.card}>
          <Select options={options} value={id} onChange={selectBuilding} />
        </div>
      </Card>
    </div>
  );
};

export default React.memo(Header);
