import * as React from "react";
import style from "./SidebarDtmfCode.scss";
import { useUser } from "src/store/user";

type Props = {};

const SidebarDtmfCode: React.FC<Props> = (props) => {
  const {} = props;
  const { ASTERISK_DTMF_OPEN_CODE } = useUser()
  return (
    <div className={style.container}>DTMF код для открытия двери: <code>{ASTERISK_DTMF_OPEN_CODE}</code></div>
  )
};

export default React.memo(SidebarDtmfCode);
